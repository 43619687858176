@import url('https://fonts.googleapis.com/css?family=Roboto&display=swap');
@import url('https://fonts.googleapis.com/css?family=Fjalla+One&display=swap');

//! set font variables
$font-body: 'Roboto', sans-serif;
$logo-font: 'Fjalla One', sans-serif;


html{
  font-size: 14px;
  font-weight: 400;

  body {
    font-family: $font-body;
    color: $black-soft;
    font-weight: 400;
    font-size: 14px;

    .logotype-font{
      font-family: $logo-font;
      text-transform: uppercase;
      font-size: 2em;
      color: #FFFFFF;
    }

    h1{
        font-family: $font-body;
        font-weight: bold;
        font-size: 24px;
    }
  }
}


@media(max-width: 376px) {
  html{
    body{
      .logotype-font{
        // font-size: 1.6em;
      }
    }
  }
}
