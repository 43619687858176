html{
  overflow-x: hidden;
  body {
    background: #EBEBEB;
    background-image: linear-gradient(to bottom, rgba(0, 0, 0, .9) 13px, rgba(255, 255, 255, 0) 460px, #EBEBEB 10px), url("images/main-background-2.jpg");
    background-position: top center;
    background-repeat: no-repeat;
    overflow-x: hidden;
  }

}

.grecaptcha-badge {
  visibility: hidden;
}

/*
 * Header
 */
.header-container {
  color: #FFFFFF;
  background-image: linear-gradient(to bottom, rgba(0, 0, 0, .9) 13px, rgba(255, 255, 255, 0) 170px, #EBEBEB 10px);

  .masthead {
    margin-bottom: 2rem;

    .masthead-brand {
      margin-bottom: 0;
      display: inline-block;

      img {
        float: left;
        margin-right: 15px;
      }
    }

    .nav-masthead {
      .nav-link {
        padding: .25rem 0;
        font-weight: 700;
        color: #FFFFFF;
        background-color: transparent;
        border-bottom: .25rem solid transparent;
      }

      .nav-link:hover,
      .nav-link:focus {
        border-bottom-color: rgba(33, 33, 33, .25);
      }

      .nav-link + .nav-link {
        margin-left: 1rem;
      }

      .active {
        color: #EEEEEE;
        border-bottom-color: #EEEEEE;
      }
    }
  }
}

#main {
  margin-top: 250px;
  background: #EBEBEB;
  padding: 30px 30px 0px 30px;

  .main-explanation {
    margin-top: -100px;
  }

  .gallery {
    margin-bottom: 20px;
  }

}

#contact {
  .title {
    text-align: center;
  }

  .contact-detail {
    padding-bottom: 30px;
  }

  #contact-form-success-message, #contact-form-failure-message {
    display: none;
  }
}

.v-align-middle {
  margin-bottom: auto;
  margin-top: auto;
}

/** Generic Elements **/
.box-wrapper {
  background: #FFFFFF;
  padding: 30px 60px;
  box-shadow: 0 1px 1px 0 rgba(0, 0, 0, .1);
  border-radius: 3px;
  margin-bottom: 20px;
  width: 100%;

  .title {
    text-align: center;
    padding-bottom: 5px;
    border-bottom: solid 1px #EBEBEB;
  }

  p{
    text-align: justify;
  }
}

.item-wrapper {
  min-height: 125px;

  .icon-wrapper {
    margin-top: 30px;
    padding-top: 15px;
    text-align: center;
    height: 60px;
    width: 60px;
    border-radius: 50%;
    background: #42abdd;
    color: #fff;
    float: left;

    > img {
      filter: invert(1);
      width: 30px;
    }
  }

  .description-wrapper {
    float: right;
    display: inline-block;
    width: calc(100% - 70px);
    padding: 30px 15px;
  }
}

.full-width {
  width: calc(100% + 60px);
  margin-left: -30px;
}

footer {
  margin-top: 30px;
  background: #1b1e21;
  color: #FFFFFF;
  position: relative;
  bottom: 0px;
  height: 150px;
  padding-top: 30px;

  .whatsapp-footer {
    color: #FFF;
  }
}

.whatsapp {
  width: 60px;
  height: 60px;
  position: fixed;
  right: 25px;
  bottom: 15px;
  background: url('images/whatsapp.svg') no-repeat;
  display: block;
}

@media (min-width: 48em) {
  .masthead-brand {
    float: left;
  }
  .nav-masthead {
    float: right;
  }
}

@media(max-width: 425px) {
  #main {
    margin-top: 100px;
  }

  .main-explanation {
    padding: 30px;
  }

  .title {
    border-bottom: 0px none;
  }
}

@media(max-width: 376px) {
  //*{outline: solid 1px red;}
  #main{
    padding: 30px 0px;
  }

  #contact-form{
    button{
      width: 100%;
      height: 60px;
    }
  }

  .header-container {
    .masthead {
      text-align: center;
      .masthead-brand {
        text-align: center;
        img{
          display: none;
        }
      }
    }
  }

  .box-wrapper{
    padding: 30px 15px 30px 45px;
    .title {
      font-size: 20px;
      margin-bottom: 15px;
      border: none;
      font-weight: bolder;
    }
    p{
      text-align: left;
    }
  }

  .item-wrapper{
    .icon-wrapper{
      float: none;
      margin-left: auto;
      margin-right: auto;
    }

    .description-wrapper{
      width: 100%;
      padding: 15px;
    }
  }
}
